@keyframes rotary {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.modal {
    position: fixed;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.7);

    align-items: center;
    justify-content: center;

    .content {
        $padding_size: 15px;
        background-color: #f3f3f3;
        width: 320px;
        border-radius: $padding_size;
        overflow: hidden;

        .header {
            height: 30px;
            font-size: 20px;
            line-height: 30px;
            padding: $padding_size;
            background-color: #e7f5fc;
        }

        .body {
            padding: $padding_size;

            .item {
                width: 100%;
                margin-bottom: 10px;
                font-size: 15px;

                .label {
                    display: inline-block;
                    width: 30%;
                    text-align: right;
                    margin-right: 10%;
                }

                .input {
                    display: inline-block;
                    width: 60%;

                    input,
                    select {
                        width: 100%;
                    }
                }
            }
        }

        .footer {
            border-top: 2px solid #333333;
            padding: $padding_size;
            text-align: right;

            .btn {
                font-size: 18px;
                width: fit-content;
                padding: 10px 20px;
                border-radius: 5px;
                display: inline-block;
                cursor: pointer;

                img.loading {
                    animation: rotary 1s linear infinite;
                    height: 15px;
                    width: 15px;
                }
            }

            .cancel {
                background-color: rgb(252, 199, 199);
                margin-right: 20px;
            }

            .confirm {
                background-color: rgb(163, 236, 176)
            }
        }
    }
}

div {
    user-select: none;
}