body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app {
    margin: auto;
}
.siteCard_site__2yh0q {
  width: 120px;
  padding: 15px;
  display: inline-block;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .siteCard_site__2yh0q .siteCard_icon-canvas__lGoWy {
    padding: 28px;
    width: 64px;
    height: 64px;
    border-radius: 10px;
    background-color: #F9f9f9;
    cursor: pointer; }
    .siteCard_site__2yh0q .siteCard_icon-canvas__lGoWy .siteCard_icon-content__hG7k3 {
      width: 64px;
      padding-top: 100%;
      overflow: hidden;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; }
  .siteCard_site__2yh0q h3 {
    font-size: 15px;
    height: 18px;
    line-height: 18px;
    word-wrap: none;
    overflow: hidden;
    text-align: center; }

@-webkit-keyframes modal_rotary__PNajV {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes modal_rotary__PNajV {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.modal_modal__1FeCg {
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center;
  justify-content: center; }
  .modal_modal__1FeCg .modal_content__3Nbfr {
    background-color: #f3f3f3;
    width: 320px;
    border-radius: 15px;
    overflow: hidden; }
    .modal_modal__1FeCg .modal_content__3Nbfr .modal_header__2diAp {
      height: 30px;
      font-size: 20px;
      line-height: 30px;
      padding: 15px;
      background-color: #e7f5fc; }
    .modal_modal__1FeCg .modal_content__3Nbfr .modal_body__PUi-9 {
      padding: 15px; }
      .modal_modal__1FeCg .modal_content__3Nbfr .modal_body__PUi-9 .modal_item__3GlKY {
        width: 100%;
        margin-bottom: 10px;
        font-size: 15px; }
        .modal_modal__1FeCg .modal_content__3Nbfr .modal_body__PUi-9 .modal_item__3GlKY .modal_label__3y_7e {
          display: inline-block;
          width: 30%;
          text-align: right;
          margin-right: 10%; }
        .modal_modal__1FeCg .modal_content__3Nbfr .modal_body__PUi-9 .modal_item__3GlKY .modal_input__3VsGx {
          display: inline-block;
          width: 60%; }
          .modal_modal__1FeCg .modal_content__3Nbfr .modal_body__PUi-9 .modal_item__3GlKY .modal_input__3VsGx input,
          .modal_modal__1FeCg .modal_content__3Nbfr .modal_body__PUi-9 .modal_item__3GlKY .modal_input__3VsGx select {
            width: 100%; }
    .modal_modal__1FeCg .modal_content__3Nbfr .modal_footer__rwwVL {
      border-top: 2px solid #333333;
      padding: 15px;
      text-align: right; }
      .modal_modal__1FeCg .modal_content__3Nbfr .modal_footer__rwwVL .modal_btn__2IPl7 {
        font-size: 18px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding: 10px 20px;
        border-radius: 5px;
        display: inline-block;
        cursor: pointer; }
        .modal_modal__1FeCg .modal_content__3Nbfr .modal_footer__rwwVL .modal_btn__2IPl7 img.modal_loading__3ZSev {
          -webkit-animation: modal_rotary__PNajV 1s linear infinite;
                  animation: modal_rotary__PNajV 1s linear infinite;
          height: 15px;
          width: 15px; }
      .modal_modal__1FeCg .modal_content__3Nbfr .modal_footer__rwwVL .modal_cancel__1jneQ {
        background-color: #fcc7c7;
        margin-right: 20px; }
      .modal_modal__1FeCg .modal_content__3Nbfr .modal_footer__rwwVL .modal_confirm__2O_pD {
        background-color: #a3ecb0; }

div {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.category_spliter__3EuKO {
  height: 45px;
  line-height: 45px;
  font-size: 20px;
  padding: 0 15px;
  background-color: #e7f5fc;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

