$icon_size: 64px;
$icon_border_size: 28px;

.site {
    width: $icon_size + 2 * $icon_border_size;
    padding: 15px;
    display: inline-block;
    user-select: none;

    .icon-canvas {
        padding: $icon_border_size;
        width: $icon_size;
        height: $icon_size;
        border-radius: 10px;
        background-color: #F9f9f9;
        cursor: pointer;

        .icon-content {
            width: $icon_size;
            padding-top: 100%;
            overflow: hidden;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    h3 {
        font-size: 15px;
        height: 18px;
        line-height: 18px;
        word-wrap: none;
        overflow: hidden;
        text-align: center;
    }
}